import { ApiHelper } from "./ApiHelper";
import * as ApiConstants from "./ApiConstants.js";

export const getCategories = (data) => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().post(ApiConstants.CATEGORY + authCode, data);
};
export const getCoursesCategory = (category_id) => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().get(
    ApiConstants.COURSES_CATEGORY + authCode + "&category_id=" + category_id
  );
};
export const getServicesCategory = () => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().post(ApiConstants.SERVICES_CATEGORY + authCode);
};
export const getPopularCourses = () => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().get(ApiConstants.POPULAR_COURSES + authCode);
};
export const getCoursesDetails = (course_id) => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().get(
    ApiConstants.COURSES_DETAILS + authCode + "&course_id=" + course_id
  );
};
export const getSubjectTopics = (subject_id) => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().get(
    ApiConstants.SUBJECT_TOPICS + authCode + "&subject_id=" + subject_id
  );
};
export const getCoursesPlans = (category_id) => {
  var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().get(
    ApiConstants.COURSES_PLANS + authCode + "&category_id=" + category_id
  );
};

export const sendMail = (data) => {
     console.log('dataaa',data);
  //   var authCode = "Rp9wJUaDdJyfKNMhqcE7iHWLBPQ6QJ9B";
  return ApiHelper.api().post(ApiConstants.SEND_MAIL, data);
};
