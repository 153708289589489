import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getCoursesCategory,
  getServicesCategory,
} from "../ApiServices/HomePageController";
import { useEffect } from "react";
import { ProgressBar } from "react-loader-spinner";
import { Modal } from "bootstrap";

export default function Header({ category_id }) {
  var pathname = window.location.pathname;

  // console.log(window.location.pathname)
  const [url, setUrl] = useState(pathname);
  const [courses, setCourses] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState(false);
  const [services, setServices] = useState();
  const [activeLink, setActiveLink] = useState(null);
  const [activePage, setActivePage] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const params = useParams();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  // let category_id = 3
  useEffect(() => {
    // getData();
    getServices();
    setActivePage(category_id);
  }, []);

  useEffect(() => {
    //for menu
    setUrl(pathname);
  }, [pathname]);
  var data = new FormData();
  data.append("category_id ", category_id);
  const getServices = () => {
    setLoadings(true);
    getServicesCategory(data).then((response) => {
      // console.log("getServices");
      console.log(response.data);

      if (response && response.data.status == "OK") {
        setServices(response.data.details);

        //  console.log(response.data.details);

        setLoadings(false);
      } else {
        setLoadings(true);
        setErrors(true);
        setLoadings(false);
      }
    });
  };
  const getCourseByCategory = (categoryId) => {
    setLoadings(true);

    getCoursesCategory(categoryId).then((response) => {
      console.log(response.data);

      if (response && response.data.status == "OK") {
        setCourses(response.data.details);

        console.log("Data Responces");

        //  console.log(response.data.details);

        setLoadings(false);
      } else {
        setLoadings(true);
        setErrors(true);
        setLoadings(false);
      }
    });
  };

  const handleSetActivePage = (page) => {
    setActivePage(page);
  };
  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleButtonClick = () => {
    setLoadings(true);

    setTimeout(() => {
      setLoadings(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };
  const location = useLocation();
  const currentPath = location.pathname;
  const currentHash = window.location.hash;
  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <>
      {/* <!-- Header Start  --> */}
      <div className="section header">
        <div className="header-bottom-section">
          <div className="container-fluid custom-container">
            <div className="header-bottom-wrap">
              <div className="header-logo-menu">
                {/* <!--  Header Logo Start  --> */}

                <div className="header-logo" >
                  <Link to="/" onClick={handleButtonClick}>
                    <img
                      src="/assets/images/logo.png"
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                </div>

                {/* <!--  Header Logo End  -->

                            <!--  Header Menu Start  --> */}
                <div className="header-menu d-none d-lg-block">
                  <ul className="main-menu">
                    <li
                      className={
                        url == "/"
                          ? "active-menu-item-has-children"
                          : "menu-item-has-children"
                      }
                    >
                      <Link to="/" onClick={handleButtonClick}>
                        Home
                      </Link>{" "}
                    </li>

                    <li
                      className={
                        url == "/services"
                          ? "active-menu-item-has-children dropdown-toggle"
                          : "menu-item-has-children dropdown-toggle"
                      }
                    >
                      Services
                      <ul className="sub-menu">
                        {services &&
                          services.map((service) => (
                            <li key={service.id} onClick={handleButtonClick}>
                              <Link
                                to={`/services/${service.id}`}
                                onClick={() => handleSetActivePage(service.id)}
                              >
                                <div
                                  className={`menu menu-item-has-childrens ${
                                    service.id == activePage
                                      ? "active-menu"
                                      : ""
                                  }`}
                                >
                                  {service.title}
                                </div>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>

                    <li
                      className={
                        url == "/About"
                          ? "active-menu-item-has-children"
                          : "menu-item-has-children"
                      }
                    >
                      <Link to="/About" onClick={handleButtonClick}>
                        About Us
                      </Link>{" "}
                    </li>

                    <li
                      className={
                        currentHash == "/contact"
                          ? "active-menu-item-has-children"
                          : "menu-item-has-children"
                      }
                    >
                      <div>
                        {" "}
                        <a href="/contact">Contact Us</a>
                      </div>
                    </li>
                    <li
                      className={
                        url == "/Blog"
                          ? "active-menu-item-has-children"
                          : "menu-item-has-children"
                      }
                    >
                      <a href="https://copeducation.com/blogs" target="_blank">
                        Blogs
                      </a>
                    </li>

                    <li className="menu-item-has-children">
                      <a href="/signup">
                        Sign Up
                      </a>
                    </li>

                    {/* Modal */}
                    {/* Bootstrap Modal */}
                    <div
                      className={`modal fade ${isModalOpen ? "show" : ""}`}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleModalClose}
                            ></button>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                    <div
                      className={`modal-backdrop fade ${
                        isModalOpen ? "show" : ""
                      }`}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    ></div>
                  </ul>
                </div>
                {/* <!--  Header Menu End  --> */}
              </div>

              {/* <!-- Header Meta Start --> */}
              <div className="header-meta">
                <div className="header-toggle d-lg-none">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
              {/* <!-- Header Meta End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Header End -->


        <!-- Offcanvas Start --> */}
      <div className="offcanvas offcanvas-start" id="offcanvasMenu">
        <div className="offcanvas-header">
          <div className="offcanvas-logo">
            {/* <a href="/">
              <a href="index.html">
                <img
                  src="/assets/images/logo.png"
                  className="img-fluidss"
                  alt="logo"
                />
              </a>
            </a> */}
              <Link to="/" onClick={handleButtonClick}>
                    <img
                      src="/assets/images/logo.png"
                      className="img-fluidss"
                      alt="logo"
                    />
                  </Link>
          </div>

          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="offcanvas"
          >
            <i className="flaticon-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li
                className={
                  url == "/About"
                    ? "active-menu-item-has-children"
                    : "menu-item-has-children"
                }
              >
                <a href="/" onClick={handleButtonClick}>
                  Home
                </a>{" "}
              </li>
              <li
                className={`menu-item-has-children ${
                  isMenuOpen ? "menu-open" : ""
                }`}
              >
                <a href="#" onClick={handleToggleMenu}>
                  Services
                  <span className="mobile-menu-expand"></span>
                  <span className="menu-icon"></span>
                  <span className="menu-expand"></span>
                </a>

                <ul
                  className="sub-menu"
                  style={{
                    boxSizing: "border-box",
                    display: isMenuOpen ? "block" : "none",
                  }}
                >
                  {services &&
                    services.map((service, index) => (
                      <li key={index}>
                        <a
                          href={`/services/${service.id}`}
                          onClick={() => setMenuOpen(false)}
                        >
                          <div
                            // className={`menu menu-item-has-childrens ${activePage === service.id ? 'active-menu' : '' }`}
                            className={`menu menu-item-has-childrens ${
                              service.id == activePage ? "active-menu" : ""
                            }`}
                          >
                            {service.title}
                          </div>
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
              {/* <li className={url == '/About'?'active-menu-item-has-children':'menu-item-has-children'}><Link to="/About" onClick={handleButtonClick}>About Us</Link> </li>
                            <li className={url == '/Contactus'?'active-menu-item-has-children':'menu-item-has-children'}><Link to="/Contactus"onClick={handleButtonClick} >Contact Us</Link>  </li>
                            <li className={url == '/Blog'?'active-menu-item-has-children':'menu-item-has-children'}><Link to="/Blog" >Blog</Link>  </li>    */}
              <li
                className={
                  url == "/About"
                    ? "active-menu-item-has-children"
                    : "menu-item-has-children"
                }
              >
                <a href="/About" onClick={handleButtonClick}>
                  About Us
                </a>{" "}
              </li>
              <li className="menu-item-has-children">
                <a href="/contact" onClick={handleButtonClick}>
                  Contact Us
                </a>
              </li>
              <li className="menu-item-has-children">
                <a href="https://copeducation.com/blogs" target="_blank">
                  Blogs
                </a>
              </li>

              <li className="menu-item-has-children">
                <a href="/signup" >
                  Sign Up
                </a>
              </li>

              <div
                className={`modal fade ${isModalOpen ? "show" : ""}`}
                style={{ display: isModalOpen ? "block" : "none" }}
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={handleModalClose}
                      ></button>
                    </div>
                    <div className="modal-body" style={{ width: "100%" }}>
                      <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdFXY45MgZMfeTEX5xEsQiItnx4a6Sgk2WqG_ay6IA2g0399A/viewform?embedded=true"
                        width="100%"
                        height="800px"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Offcanvas End --> */}
    </>
  );
  // }
}
