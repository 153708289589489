export default function Banner() {
  return (
    <>
      <div className="upstudy-hero-section-03 section col-lg-6 col-sm-12">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 col-sm-12 mt-5">
              <div className="hero-content ">
                <img
                  src="assets/images/image 5.png"
                  alt=""
                  className="image-overlay-1"
                />
                <img
                  src="assets/images/Atom.png"
                  alt="Overlay Image"
                  className="image-overlay"
                />
                <img
                  src="assets/images/image 78.png"
                  alt="Overlay Image"
                  className="image-overlay-2"
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="hero-images">
                <h1 className="Titel">
                  {/* One stop solution <br/>with diploma <br/>Engineering */}
                  We are here to Revolutionize Diploma Education
                </h1>
                <div className="sub-titel" style={{ color: "rgb(227 180 110)" }}>
                  {/* CLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minLorem ipsum dolor sed do eiusmod tempor incididunt.....! */}
                  By the Alumni of IIT Bombay
                </div>
              <div className="mouse">
              <div className="row" style={{ flexWrap:"nowrap"}} >
                  <div className="col-sm-8 col-md-9 col-lg-9">
                    <div className="Myfild">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Enter your email..."
                      />
                      <a href="/signup">
                        {" "}
                        <button type="button" className="small-button">
                          Sign Up
                        </button>
                      </a>
                      {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSdFXY45MgZMfeTEX5xEsQiItnx4a6Sgk2WqG_ay6IA2g0399A/viewform?usp=sf_link"><button className="btn btn-primary btn-sm"><i className="fa fa-arrow"></i>Submit</button></a> */}
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3" >
                    <img
                      src="assets/images/black.png"
                      alt=""
                      className="black"
                    />
                    <img
                      src="assets/images/image 78.png"
                      alt="Overlay Image"
                      className="image-overlay-3"
                    />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row bd-highlight mb-5 mt-5 align-items-center">
            <h1 className="small-text">
              {/* Get a FREE Trial.<br/> Download App Now! */}
              To Explore the Courses.. Download the App Now
            </h1>
            <div className="online" type="button">
              <img src="assets/images/Group.png" alt="" />
            </div>
            <div className="online" type="button">
              <img src="assets/images/Group-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
