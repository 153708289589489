import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    setLoading(true);

    // Simulate an asynchronous action, like fetching data from API or navigation delay
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };
  //   const handleLogoClick = () => {
  //     window.location.reload();
  //   };

  return (
    <>
      <div className="footer-section section">
        <div className="container ">
          {/*<!-- Footer Widget Wrapper Start -->*/}
          <div className="footer-widget-wrap">
            <div className="row" id="Contacts">
              <div className="col-lg-5 col-sm-6">
                <div className="footer-widget widget-about">
                  <div className="footer-logo">
                    <Link to="/" onClick={handleButtonClick}>
                      <img
                        src="/assets/images/logo.png"
                        alt=" no image "
                        className="foot-logo"
                      />
                    </Link>
                  </div>
                  <p className="foot-text">
                    We support programs that create
                    <br /> advancement for people
                  </p>
                  <div className="widget-info">
                    <div className="info-icon">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/ECETbyCOP?mibextid=ZbWKwL"
                      >
                        {" "}
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/copt-education-pvt-ltd/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>

                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://instagram.com/copeducation?igshid=NGExMmI2YTkyZg=="
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/channel/UCbjvrWk3xlhInF9WMbgr32g"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/*<!-- Footer Widget End -->*/}
              </div>

              <div className="col-lg-3 col-sm-6 text-start">
                {/*<!-- Footer Widget Start -->*/}
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Useful links</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <Link to="/Conditions" onClick={handleButtonClick}>
                          Terms and Conditions{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Privacy" onClick={handleButtonClick}>
                          Privacy Policy{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/refundpolicy" onClick={handleButtonClick}>
                          Refund Policy{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 text-start">
                {/*<!-- Footer Widget Start -->*/}
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Contact Us</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <a href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCKHQckZnvrbnTXLXLnprbQWNgNCfSblhLDghTWvpspJWFGqlRtpDtXdLGDPpvRPsNPlXQjB">
                          <div className="row">
                            <div className="col-2">
                              <i className="flaticon-email" id="Icon"></i>{" "}
                            </div>
                            <div className="col-10">
                             <p> support@copeducation.com</p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="tel:8309003193" id="">
                          <i className="flaticon-phone-call" id="Icon"></i> +91
                          8309003193
                        </a>
                      </li>
                      <li>
                        <a href="https://www.google.com/maps/search/+Regd.off.:+Plot+No+51,+SY+No+74,+Maruthi+Nagar,+Medchal,+Ranga+Reddy-501401,+Telangana../@17.5778352,78.4048,12z/data=!3m1!4b1?entry=ttu">
                          <div className="row">
                            <div className="col-2">
                              <i
                                className="flaticon-location-pin"
                                id="Icon"
                              ></i>{" "}
                            </div>
                            <div className="col-10">
                              <p style={{ textAlign: "justify" }}>
                                {" "}
                                Regd.off.: Plot No 51, SY No 74, Maruthi Nagar,
                                Medchal, Ranga Reddy-501401, Telangana..
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.google.com/maps/search/+Corp.off.:+3-5-1093%2FA+18,+Venkateshwara+Colony,+Narayanguda,+Hyderabad-500029,+Telangana./@17.4347367,78.3234846,12z/data=!3m1!4b1?entry=ttu">
                          <div className="row">
                            <div className="col-2">
                              <i
                                className="flaticon-location-pin"
                                id="Icon"
                              ></i>
                            </div>
                            <div className="col-10">
                              <p style={{ textAlign: "justify" }}>
                                Corp.off.: 3-5-1093/A 18, Venkateshwara Colony,
                                Narayanguda, Hyderabad-500029, Telangana.
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <div className="copyright-wrapper">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-6">
                  <div className="copyright-text">
                    <p>
                      © Copyright 2024 COPT Education PVT Limited. || All rights
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
