import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './HomePage/Home';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import About from './About/About';
import Services from './Services/Services';
import SubServices from './SubServices/SubServices';
import Conditions from './Termsandconditions/Conditions';
import Privacy from './Privacypolicy/Privacy';
import Refundpolicy from './Refund/Refundpolicy';
import Blog from './Blogs/Blog';
import Contactus from './Contact/Contactus';
import SignUp from './FullHomepage/SignUp';
function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About/>}/>
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/contact" element={<Contactus/>}/>
        <Route path="/conditions" element={<Conditions/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/refundpolicy" element={<Refundpolicy/>}/>
        <Route path="services/:id" element={<Services/>}/>
        <Route path='/services/sub-services/:id'element={<SubServices/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
