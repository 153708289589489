import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../ApiServices/HomePageController";
import { ProgressBar } from "react-loader-spinner";

export default function AllCourses() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); // Add this line
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getCategories({ limit: 4 }).then((response) => {
      if (response && response.data.status === "OK") {
        setCategories(response.data.details);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    });
  };

  const handleButtonClick = () => {
    setLoading(true);

    // Simulate an asynchronous action, like fetching data from API or navigation delay
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
      setShowMessage(false); // Reset the error message if necessary
    }, 0);
  };

  return (
    <div className="container ">
      <div className="row justify-content-center">
        <div className="d-flex justify-content-between mb-3 mt-5">
          <h3 className="Brand">Take a course</h3>
          {categories.length > 0 && (
            <Link to={`/services/${categories[0].id}`}>
              <button
                type="button"
                className="Brand-btn"
                onClick={handleButtonClick}
              >
                All courses
              </button>
            </Link>
          )}
          {showMessage && (
            <div style={{ textAlign: "center", color: "red" }}>
              Please select a course
            </div>
          )}
        </div>
        <div className="row mb-5 text-center ">
          {categories.map((category) => (
            <div className=" marginmobile col-md-3 col-sm-6 col-xl-3 mb-2 " key={category.id}>
              <Link
                className="cours-box"
                to={`/services/${category.id}`}
                onClick={handleButtonClick}
              >
                <div className="cours-small">
                  {loading ? (
                    <ProgressBar
                      type="Oval"
                      color="#00BFFF"
                      height={80}
                      width={80}
                    />
                  ) : (
                    <img
                      src={category.image}
                      className="img-fluids"
                      alt={category.title}
                    />
                  )}
                </div>
                <p className="cours-smal">{category.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
