import { useEffect, useState } from "react";
import { sendMail } from "../ApiServices/HomePageController";
import { toast } from "react-toastify";
import { useSnackbar } from "notistack";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Contactus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [mail, setMail] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const nameRegex = /^[A-Za-z\s]+$/; // Regular expression to allow only alphabets and spaces in the name
  const phoneNumberRegex = /^[0-9]+$/; // Regular expression to allow only numbers in the phone number

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const errors = {};

    if (!name.trim()) {
      errors.name = "Name is required";
    } else if (!name.match(nameRegex)) {
      errors.name = "only alphabets.";
    }

    // Validate Phone Number
    if (!phoneNumber.match(phoneNumberRegex)) {
      errors.phoneNumber = " only numbers.";
    }

    // Validate Email: Required field and should be a valid email format
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email format";
    }

    // Validate Role: Required field
    if (!role) {
      errors.role = "Please select a role";
    }

    // If there are errors, set them in the state
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const formData = new FormData();

      // Append form data to the FormData instance
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contact_no", phoneNumber);
      formData.append("type", role);
      formData.append("message", message);

      console.log(formData);

      sendMail(formData).then((response) => {
        if (response && response.data.status === "OK") {
          setMail(response.data.details);
          console.log(response);
          enqueueSnackbar(
            "Thank You!! For Contacting Us. Our Team Will Get Back To You Soon",
            { variant: "success" }
          );
          // Reset form state
          setName("");
          setEmail("");
          setPhoneNumber("");
          setRole("");
          setMessage("");
          setFormErrors({});
          setLoading(false);
        } else {
          enqueueSnackbar("Error Sending Mail Please Try Again", {
            variant: "error",
          });
          setLoading(false);

          // setError(true);
          // setLoading(false);
        }
      });

      console.log("Name:", name);
      console.log("Email:", email);
      console.log("Phone Number:", phoneNumber);
      console.log("Role:", role);
      console.log("Message:", message);

      // setFormSubmitted(true);
    }
  };
  return (
    <>
      <Header></Header>
      <div className="container">
        <p className="section-sub"></p>
      </div>

      <div className="container" style={{ paddingTop: "30px" }}>
        <div className="row contact align-items-center">
          <div className="col-sm-6 text-start ">
            <p className="Courses-p"></p>
            <div className="d-flex justify-content-between  mb-3 mt-5">
              <h3 className="Brands"> Contact Us</h3>
              {/* <button type="button" className="Brand-btn ">All courses</button> */}
            </div>
            <div className="contact-titel">
              Elevate Your <span className="contact-span">Learning!</span>Let’s
              discuss
            </div>
            <div className="contact-sub text-start-0">
              Thank you for getting in touch!
              <br />
              Kindly. Fill the form, have a great day!
            </div>
          </div>

          <div
            className="col-sm-6 text-center pt-5"
            style={{ margin: "43px 0 0 0" }}
          >
            <div className="date">
              <div className="row">
                <div className="form-field col-sm-6">
                  <input
                    className="inp"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    pattern="[A-Za-z\s]+"
                    title="Name can only contain alphabets and spaces."
                  />
                  {formErrors.name && (
                    <div className="error text-danger">{formErrors.name}</div>
                  )}
                </div>

                <div className="form-field col-sm-6">
                  <input
                    className="inp"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* Add validation and error message for email if needed */}
                  {formErrors.email && (
                    <div className="error text-danger">{formErrors.email}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-field col-sm-6">
                  <input
                    className="inp"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    pattern="[0-9]+"
                    title="Phone number can only contain numbers."
                  />
                  {formErrors.phoneNumber && (
                    <div className="error text-danger">
                      {formErrors.phoneNumber}
                    </div>
                  )}
                </div>

                <div className="form-field col-sm-6">
                  <select
                    name="role"
                    className="inp text-start"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    placeholder="Select one"
                  >
                    <option value="">---Select---</option>
                    <option value="Student">Student</option>
                    <option value="Teacher">Teacher</option>
                    <option value="College">College</option>
                    <option value="Company">Company</option>
                    <option value="3rd Party Services">
                      3rd Party Services
                    </option>
                  </select>
                  {formErrors.role && (
                    <div className="error text-danger">{formErrors.role}</div>
                  )}
                </div>
              </div>
            </div>

            <input
              className="put"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {console.log(loading)}
            <div className="click text-button mt-2">
              <button
                type="submit"
                className="btn btn-primary pr-3 btn-sm"
                onClick={handleSubmit}
                disabled={loading}
                style={{ width: "113px" }}
              >
                <i className="fa fa-arrow"></i> Submit
              </button>
              {console.log(loading)}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
