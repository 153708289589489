import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Banner from "../FullHomepage/Banner";
import Categorys from "../FullHomepage/Categorys";
import Aboutbody from "../FullHomepage/Aboutbody";
import Features from "../FullHomepage/Features";
import Courses from "../FullHomepage/Courses";
import ContactUs from "../FullHomepage/ContactUs";
import Footer from "../Footer/Footer";
import AllCourses from "../FullHomepage/AllCourses";
import CarouselScreen from "../FullHomepage/CarouselScreen";
import { ProgressBar } from "react-loader-spinner";
export default function Home() {
  // const [loadings, setLoadings] = useState(false);

  return (
    <>
      {/* Header */}

      <Header />

      {/* headerEnd */}
      {/* banner */}
      <Banner />
      {/* bannerEnd */}

      {/*<!-- row Itams -->*/}
      {/*<!-- Itams End -->*/}
      <AllCourses />
      {/*<!-- All Courses -->*/}

      {/*<!-- All Courses End  -->*/}
      <Aboutbody />
      <Features />
      {/*<!-- Category Start -->*/}
      {/*<!-- Category End -->*/}

      <CarouselScreen />

      {/*<!-- Brand Start -->*/}
      <Courses />
      {/*<!-- Brand End -->*/}
      <ContactUs />
      {/*<!-- Footer Start -->*/}

      <Footer />
      {/* {loadings && (
      <div className="small-loading-indicator">
        <ProgressBar type="Oval" color="#00BFFF" height={20} width={20} />
      </div>
    )} */}
    </>
  );
}
